.navbar {
  &:not(.scrolled) {
    img {
      filter: brightness(100)
    }
  }
}


ul.diagnostics-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
  width: fit-content;
}

.circle-style {
  background-color: #FFC107;
  height: 40px;
  width: 40px;
  color: #096AA4;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  font-size: 32px;
  line-height: .9;
}

a {
  color: #555a64;

  &:hover {
    color: #555a64;
  }
}

.makers-style {
  height: 300px;
}

.backdrop-filter {
  background-color: rgba(#dddddd, .3);
}

.slick-slide {
  padding: 0 4px;
}

.slick-next,
.slick-prev {
  height: 35px;
  width: 35px;

  &::before {
    color: #0769A5;
    font-size: 50px;
  }
}

.slick-next {
  right: -80px;
}

.slick-prev {
  left: -100px;
}

.f-size18 {
  font-size: 18px;
  line-height: 2;
  font-weight: normal;
}

.f-size22 {
  font-size: 22px;
  font-weight: normal;
}

.f-size28 {
  font-size: 1.75rem;
}

.f-size16 {
  font-size: 16px;
}

.f-size21 {
  font-size: 21px;
}

.l-height {
  line-height: 2.90rem;
}

.nav-item {
  font-size: 1.125rem;

  a.active {
    color: #0769ab;
  }
}

.display-5 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 4rem;

  @media (max-width: 1200px) {
    .display-5 {
      font-size: calc(1.375rem + 1.5vw);
    }
  }
}

.lead {
  font-size: 24px;
  font-weight: 600;
  color: #0769ab;
}

h1 {
  font-size: 48px;
  color: #0769ab;
}

h3 {
  color: #0769ab;
}

h5 {
  font-size: 1.125rem;
  color: #0769ab;

  a {
    color: #0769ab;

    &:hover {
      color: rgba(#0769ab, .9);
    }
  }
}

.h100 {
  height: 100%;
}

.w32 {
  width: 32px;
  margin-right: 10px;
}

.w50 {
  width: 50%;
  margin: 0 auto;
}


.logo-box {
  height: 115px;
}

.services-grid,
.execution-services-grid,
.execution-sub-services-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  row-gap: 50px;
}

.execution-services-grid {
  grid-template-columns: 1fr 1fr 1fr;
  align-items: flex-start;

  .height-400 {
    height: 450px;
  }
}

.execution-sub-services-grid {
  .height-400 {
    height: 100%;
  }

  .brochure-btn-content {
    margin-top: 0 !important;
  }

  .brochure-btn-down {
    display: none;
  }
}

.grid-video {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  row-gap: 50px;
}

.footer-tag {
  text-align: center;
  background-color: rgba(#dddddd, .3);
  font-size: 21px;


  p {
    margin: 0;
    padding: 20px;
    color: #555a64;

    strong {
      color: #0769ab;
    }
  }
}

.bg-yellow {
  background-color: #FFC107;
}

.learn-btn,
.learn-btn-down,
.brochure-btn-down {
  color: #0769ab;
  background-color: #FEBB2D;
  padding: 2px 20px;
  font-weight: bold;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;

  &:hover {
    color: rgba(#0769ab, .9);
    background-color: rgba(#FEBB2D, .9);
    transition: all .3s ease;
  }
}

.learn-btn-down {
  padding: 2px 50px;
}

.brochure-btn-down {
  padding: 5px 30px;
}

.btn-primary {
  background-color: #FEBB2D;
  color: #0769ab;
  border-color: #FEBB2D;

  &:hover {
    color: rgba(#0769ab, .9);
    background-color: rgba(#FEBB2D, .9);
    transition: all .3s ease;
    border-color: transparent;
  }
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  background-color: #fff;
  border-color: #0769ab;
}

.custom-control-input:checked~.custom-control-label::before {
  background-color: #0769ab;
  border-color: #0769ab;
}

label {
  a {
    color: #0769ab;

    &:hover {
      color: rgba(#0769ab, .9);
      transition: all .3s ease;
    }
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #096aa4;
  border-color: #096aa4;
}

.form-control:focus {
  border-color: #0769ab;
}