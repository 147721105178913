@media only screen and (max-width: 600px) {
  .mobile-mb {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .mobile-mt-10 {
    margin-top: 10px;
  }

  .mobile-center {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .diagnostics-list {
    li {
      span {
        font-size: 12px;
      }
    }
  }

  .services-grid,
  .execution-services-grid,
  .execution-sub-services-grid {
    grid-template-columns: none;
  }

  .execution-services-grid {
    .height-400 {
      height: 400px;
    }
  }

  .carousel-cell {
    span {
      font-size: 12px;
    }

    p {
      font-size: 14px;
      width: 80%;
      margin: 0 auto;
    }
  }

  .customers {
    .logo-box {
      margin-bottom: 30px;
      align-items: center;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .grid-video {
    grid-template-columns: none;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .mobile-contact {
    flex-direction: column;
  }

  .mobile-back-to-top {
    right: 15px;
  }

  .slick-prev {
    left: 0;
    width: 25px;
    height: 25px;

    &::before {
      font-size: 25px;
    }
  }

  .slick-next {
    right: 0px;
    width: 25px;
    height: 25px;

    &::before {
      font-size: 25px;
    }
  }

  .brochure-btn-content {
    margin-top: 0 !important;
  }

  .footer-tag {
    .container {
      flex-direction: column;

      .founder {
        img {
          margin-top: 10px;
        }
      }
    }
  }
}